// Generated by Framer (0b5b7f5)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["LsDzyF7jF", "bJ3PnN88p"];

const serializationHash = "framer-LDewO"

const variantClassNames = {bJ3PnN88p: "framer-v-1s8va9j", LsDzyF7jF: "framer-v-1atf4aw"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 30, delay: 0, mass: 1, stiffness: 400, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Menu: "LsDzyF7jF", X: "bJ3PnN88p"}

const getProps = ({color, height, id, tap, width, ...props}) => { return {...props, mP9Tz30oK: color ?? props.mP9Tz30oK ?? "var(--token-e0ce79e8-f023-49ad-9edf-bfcca13d71cf, rgb(7, 15, 75))", TBgHLr7MW: tap ?? props.TBgHLr7MW, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "LsDzyF7jF"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;tap?: any;color?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, TBgHLr7MW, mP9Tz30oK, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "LsDzyF7jF", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap1h1dzoi = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (TBgHLr7MW) {const res = await TBgHLr7MW(...args);
if (res === false) return false;}
setVariant("bJ3PnN88p")
})

const onTap1qhfwtw = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (TBgHLr7MW) {const res = await TBgHLr7MW(...args);
if (res === false) return false;}
setVariant("LsDzyF7jF")
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1atf4aw", className, classNames)} data-framer-name={"Menu"} data-highlight layoutDependency={layoutDependency} layoutId={"LsDzyF7jF"} onTap={onTap1h1dzoi} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({bJ3PnN88p: {"data-framer-name": "X", onTap: onTap1qhfwtw}}, baseVariant, gestureVariant)}><motion.div className={"framer-1j0i8c8"} data-framer-name={"Bottom Line"} layoutDependency={layoutDependency} layoutId={"TxjJcP4PV"} style={{backgroundColor: mP9Tz30oK, rotate: 0}} variants={{bJ3PnN88p: {rotate: -45}}}/><motion.div className={"framer-tkpm31"} data-framer-name={"Middle Line"} layoutDependency={layoutDependency} layoutId={"XyjrL5YqX"} style={{backgroundColor: mP9Tz30oK, opacity: 1}} variants={{bJ3PnN88p: {opacity: 0}}}/><motion.div className={"framer-103nrem"} data-framer-name={"Top Line"} layoutDependency={layoutDependency} layoutId={"Y2idnyJqo"} style={{backgroundColor: mP9Tz30oK, rotate: 0}} variants={{bJ3PnN88p: {rotate: 45}}}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-LDewO.framer-fnctgh, .framer-LDewO .framer-fnctgh { display: block; }", ".framer-LDewO.framer-1atf4aw { cursor: pointer; height: 32px; overflow: hidden; position: relative; width: 32px; }", ".framer-LDewO .framer-1j0i8c8 { bottom: 7px; flex: none; height: 2px; left: calc(50.00000000000002% - 24px / 2); overflow: hidden; position: absolute; width: 24px; }", ".framer-LDewO .framer-tkpm31 { flex: none; height: 2px; left: calc(50.00000000000002% - 24px / 2); overflow: hidden; position: absolute; top: calc(50.00000000000002% - 2px / 2); width: 24px; }", ".framer-LDewO .framer-103nrem { flex: none; height: 2px; left: calc(50.00000000000002% - 24px / 2); overflow: hidden; position: absolute; top: 7px; width: 24px; }", ".framer-LDewO.framer-v-1s8va9j.framer-1atf4aw { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 32px); }", ".framer-LDewO.framer-v-1s8va9j .framer-1j0i8c8 { bottom: unset; top: calc(50.00000000000002% - 2px / 2); }", ".framer-LDewO.framer-v-1s8va9j .framer-tkpm31 { left: calc(50.00000000000002% - 2px / 2); width: 2px; }", ".framer-LDewO.framer-v-1s8va9j .framer-103nrem { left: unset; right: 4px; top: calc(50.00000000000002% - 2px / 2); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 32
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"bJ3PnN88p":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"TBgHLr7MW":"tap","mP9Tz30oK":"color"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerYs2psWkg3: React.ComponentType<Props> = withCSS(Component, css, "framer-LDewO") as typeof Component;
export default FramerYs2psWkg3;

FramerYs2psWkg3.displayName = "Menu Icon Copy 2";

FramerYs2psWkg3.defaultProps = {height: 32, width: 32};

addPropertyControls(FramerYs2psWkg3, {variant: {options: ["LsDzyF7jF", "bJ3PnN88p"], optionTitles: ["Menu", "X"], title: "Variant", type: ControlType.Enum}, TBgHLr7MW: {title: "Tap", type: ControlType.EventHandler}, mP9Tz30oK: {defaultValue: "var(--token-e0ce79e8-f023-49ad-9edf-bfcca13d71cf, rgb(7, 15, 75)) /* {\"name\":\"Background - Dark\"} */", title: "Color", type: ControlType.Color}} as any)

addFonts(FramerYs2psWkg3, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})